import { default as React, useContext, useEffect, useState, useCallback } from 'react'
import { Card, Collapse, Typography } from '@mui/material'
import ContactTable from '../../facilityManagement/ContactTable';
import { ROLES } from '../../auth/role.js'
import RoleContext from '../../auth/RoleContext.js'
import { ParentType, getEntityContacts, getContactTypes } from '../../facilityManagement/data'
import HelpGuidanceCollapsible from '../../help/HelpGuidanceCollapsible.js';

export default function ManufacturerContactsSection(props = {}) {

  const roleDetails = useContext(RoleContext)
  const { manufacturer,
    manufacturerProfile,
    openAssessment, 
    editMode, 
    setSectionIsEditable 
  } = props

  const [loading, setLoading] = useState(true);

  const [contacts, setContacts] = React.useState([]);
  const [contactTypes, setContactTypes] = React.useState([]);


  const loadSection = async (mfrUuid) => {
    setLoading(true)
    const [_contacts, _contactTypes] = await Promise.all([
      getEntityContacts({
        facilityOrOwnershipUuid: mfrUuid,
        parentType: ParentType.manufacturer,
        category: 'assessment'
      }),
      getContactTypes('assessment'),
    ])
    setContacts(_contacts)
    setContactTypes(_contactTypes)
    setLoading(false)
  }

  useEffect(() => {
    if (manufacturer?.uuid) loadSection(manufacturer?.uuid)
  }, [manufacturer, manufacturerProfile])
  useEffect(() => {
    setSectionIsEditable(false)
  }, [])

  const isEditable = () => {
    if (roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])) {
      return true
    }
    if (openAssessment && roleDetails.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP])) {
      return true
    }
    return false
  }

  const afterSave = useCallback(() => {
    (async () => {
      if (manufacturer?.uuid) loadSection(manufacturer?.uuid)
    })()
  }, [manufacturer])

  const helpMessage = 
`> Key Contacts
> Involved in Assessment
> Corporate Involved in Assessment
> Post-Audit Distribution Stakeholders`

  return (
    <>
    <HelpGuidanceCollapsible helpTitle='Please add contacts for each of the following types:' helpMessage={helpMessage} />
    <ContactTable
        loading={loading}
        parentType={ParentType.manufacturer}
        facilityOrOwnership={manufacturer}
        editMode={isEditable()}
        contacts={contacts}
        setContacts={setContacts}
        contactTypes={contactTypes}
        afterSave={afterSave}
      />
    </>
  )
}