import axios from "axios"
import { getRequestConfig, getToken } from "./auth"
import { ApiGateway } from "../config/config"

export const getUserProfile = async () => {
  const result = { token: undefined, profile: undefined }
  const token = await getToken()
  const koId = token?.idTokenClaims?.koId
  if (koId) {
    const basePath = `${ApiGateway.profile}/${koId}?include=manufacturers`
    const options = {
      headers: {        
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.idToken}`
      }
    }
    const response = await axios.get(basePath, options)
    result.token = token
    result.profile = response.data
  }
  return result
}