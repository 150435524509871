import { default as React, useEffect, useState, useMemo, useContext } from 'react'
import { Form, useNavigate } from 'react-router-dom'
import { TaskAlt } from '@mui/icons-material'
import { Box, Grid, Paper, IconButton, FormControl, FormElement, InputLabel, Select, MenuItem, Typography } from '@mui/material'
import ManufacturerAutocomplete from '../form/ManufacturerAutocomplete'
import QSEAutocomplete from '../form/QSEAutoComplete'
import WizardCommandBar from '../commandBar/WizardCommandBar'
import Page from '../layout/Page'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import { getRequestConfig, getToken } from '../auth/auth'
import { ApiGateway, FeatureFlags } from '../config/config'
import axios from 'axios'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FileListControl from '../form/FileListControl'

export default function AuthorizationFilesCard(props = {}) {
  const roleDetails = useContext(RoleContext)

  const { mfrProfile, authorization } = props

  useEffect(() => {
    
  }, [authorization])

  const isMfiAdminOrAbove = roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN])
  const editable = FeatureFlags.facAuthQseUpdates ? authorization.workflowStatus === 'DRAFT' || (isMfiAdminOrAbove && authorization.isHistorical === true) : authorization.workflowStatus === 'DRAFT'

  return (
    <RoleContext.Consumer>
      {roleCtx => (

        <Paper elevation={1} sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', margin: '1em auto', padding: "1em" }}>
          <Grid container columnGap={1} rowGap={1} spacing={1}>

            {/* Latest Letter */}
            <Grid item xs={12}>
              <Typography fontWeight='bold' variant="body2" color="text.secondary">Latest authorization letter</Typography>
              <br />
              <FileListControl
                listFilesUrl={`${ApiGateway.authorizations}/${authorization.id}/files`}
                showLastModified={true}
                showActions={editable}
                enableUpload={editable}
                enableDownload={true}
                getFileUrlServiceAddr={`${ApiGateway.authorizations}/${authorization.id}/files/`}
                getPutFileUrlServiceAddr={`${ApiGateway.authorizations}/${authorization.id}/files/new-file-url`}
                removeFileUrl={`${ApiGateway.authorizations}/${authorization.id}/files`}
                altFileNameColumnLabel='Original File Name'
              />
            </Grid>

            {/* Past Letters */}
            {/* <Grid item xs={4}>
              <Typography fontWeight='bold' variant="body2" color="text.secondary">Latest authorization letter</Typography>
              <br />
              <FileListControl
                showLastModified={true}
                showActions={true}
                enableUpload={true}
                enableDownload={true}
              />
            </Grid> */}

          </Grid>
        </Paper>
      )}
    </RoleContext.Consumer>
  )
}