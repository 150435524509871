import { default as React, useEffect, useState, useRef, useMemo, useCallback, useContext } from 'react'
import { Form, useNavigate, useParams } from 'react-router-dom'
import { TaskAlt, TextDecrease, HistoryEdu } from '@mui/icons-material'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material'
import RoleContext from '../auth/RoleContext'
import { getRequestConfig, getToken } from '../auth/auth'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import cocaColaNaB64 from './CocaColaLogoB64.js'
import UserContext from '../auth/UserContext'
import { LetterTypes, SIGNATURE_ATTESTATION, approvalStatuses, toFormattedDate } from './common.js'
import { toPascalCase } from '../utils/TextUtils.js'
import lodash from 'lodash'

export const styles = {
  letter: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  innerLetter: {
    maxWidth: '800px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    fontFamily: 'arial',
    fontSize: '12px',
    padding: '20px'
  },
  body: {
    // display: 'table',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    width: '100%',
    height: '70px',
    fontSize: '16px',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '30px',
    fontSize: '12px',
    padding: '20px',
    backgroundColor: 'white',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  footerPages: {

  },
  footerVersion: {

  },
  logo: {
    width: '0%'
  },
  logoImg: {
    width: '200px'
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleH1: {
    marginBottom: '0px'
  },
  rowBuffer: {
    width: '100%',
    height: '20px',
  },
  whiteRowBuffer: {
    width: '100%',
    height: '20px',
    backgroundColor: 'white'
  },
  infoSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    pageBreakInside: 'auto'
  },
  alignCenter: {
    textAlign: 'center'
  },
  alignLeft: {
    textAlign: 'left'
  },
  tableRow: {
    width: '100%',
    pageBreakInside: 'avoid',
    pageBreakAfter: 'auto'
  },
  tableHeader: {
    border: '1px solid #dddddd',
    padding: '4px',
    backgroundColor: '#DDE5F0',
    fontSize: '12px',
  },
  tableCell: {
    border: '1px solid #dddddd',
    padding: '4px',
    fontSize: '12px'
  },
  signature: {
    barokah: {
      marginBottom: '-8px',
      lineHeight: '32px',
      fontFamily: 'Barokah',
      fontWeight: '800',
      fontSize: '12px'
    },
    famulred: {
      marginBottom: '24px',
      marginLeft: '4px',
      fontFamily: 'Famulred',
      lineHeight: '12px',
      fontSize: '60px',
    },
    pwmanuel: {
      marginBottom: '-6px',
      marginLeft: '6px',
      lineHeight: '36px',
      fontFamily: 'PWManuel',
      fontSize: '36px',
    }
  },
  signatureBox: {
    marginTop: '20px'
  },
  signPrompt: {
    width: '200px'
  }
}

export default function AuthorizationLetterTemplate(props = {
  signatureStyle: undefined,
  onClickSign: undefined,
  letterRef: undefined,
  headerRef: undefined,
  bodyRef: undefined,
  footerRef: undefined,
  authorization: undefined,
  mfrProfile: undefined,
  approvalCategories: undefined,
  date: undefined,
  signed: undefined,
  letterBody: undefined,
  templateRefs: undefined,
  templateData: undefined
}) {

  const params = useParams()
  const userDetails = useContext(UserContext)
  const { firstName, lastName } = userDetails?.userProfile || {}

  const {
    templateRefs,
    templateData,
    letterRef,
    headerRef,
    bodyRef,
    footerRef,
    authorization,
    mfrProfile,
    approvalCategories,
    date,
    signed
  } = props

  const authStatus =
    authorization.approvalStatus === 'FULL' ? 'fully authorized' :
      authorization.approvalStatus === 'CONDITIONAL' ? 'conditionally authorized' :
        authorization.approvalStatus === 'DEAUTHORIZED' ? 'deauthorized' : ''

  const uniqueCategories = Array.from(new Set(authorization?.Approvals?.map(a => a.categoryId)))

  const hasMostSensitiveCol = (categoryId) => {
    return ['APPROVED_PRODUCTION_PROCS'].includes(categoryId)
  }

  const hasLineCol = (categoryId) => {
    return ['PROCESS_CHANGE_VALIDATIONS',
      'REDUCE_MONITORING_FREQUENCIES',
      'EQUIPMENT_TECH_VALIDATION',
      'APPROVED_PRODUCTION_PROCS'].includes(categoryId)
  }

  const hasAtLeastOneProvisionalApproval = authorization?.Approvals?.some(a => a.approvalStatus === approvalStatuses.PROVISIONAL)

  const approvalsByCategory = uniqueCategories.map(categoryId => {
    return {
      cols: hasMostSensitiveCol(categoryId) ? 5 :
        hasLineCol(categoryId) ? 4 : 3,
      categoryId,
      categoryLabel: approvalCategories.find(ac => ac.id === categoryId)?.label,
      approvals: authorization?.Approvals.filter(a => a.categoryId === categoryId)
    }
  })

  const EmptyRow = () => <div style={styles.rowBuffer}>&nbsp;</div>
  const EmptyWhiteRow = () => <div style={styles.whiteRowBuffer}>&nbsp;</div>

  const IssuedDate = () => {
    if (authorization.letterType === LetterTypes.NEW) {
      return <div>
        <span><b>Date of Issue:</b></span>
        <span> {date}</span>
      </div>
    }
    return <div>
      <span><b>Date of Issue:</b></span>
      <span> {toFormattedDate(authorization.issuedDate)}</span>
    </div>

  }

  const ReviewedDate = () => {
    if (authorization.letterType === LetterTypes.REVIEW) {
      return <div>
        <span><b>Reviewed:</b></span>
        <span> {date}</span>
      </div>
    }
    if (authorization.reviewedDate) {
      return <div>
        <span><b>Reviewed:</b></span>
        <span> {toFormattedDate(authorization.reviewedDate)}</span>
      </div>
    }
  }

  const RevisionDate = () => {
    if (authorization.letterType === LetterTypes.REVISION) {
      return <div>
        <span><b>Revision {authorization.revisionNumber}:</b></span>
        <span> {date}</span>
      </div>
    }
    if (authorization.revisionDate) {
      return <div>
        <span><b>Revision {authorization.revisionNumber}:</b></span>
        <span> {toFormattedDate(authorization.revisionDate)}</span>
      </div>
    }
  }

  const removeP = (htmlString = "") => {
    return htmlString.replace(/<p>|<\/p>/gi, '')
  }

  const replaceTemplate = (template = "", data) => {
    const templateMatches = template.match(/{([a-zA-Z])*}/g) || []
    const templated = templateMatches.reduce((s, match) => {
      const word = match.replace('{', '').replace('}', '')
      const replaced = lodash.get(data, word) || `<b>{${word} NOT FOUND}</b>`
      return s.replace(match, replaced)
    }, `${template}`)
    return templated
  }

  const templateVariables = useMemo(() => {
    const vars = {
      ...authorization,
      ...mfrProfile,
      ...mfrProfile.Manufacturer,
      authStatus
    }
    delete vars.Manaufacturer
    delete vars.Approvals
    return vars
  }, [authorization, mfrProfile, authStatus])

  const TemplatedText = useCallback((props = {}) => {
    const { id } = props
    const data = templateVariables
    const value = removeP(templateData?.find(f => f.id === id)?.value)
    const templatedValue = replaceTemplate(value, data)
    return <span
      ref={templateRefs[id]}
      dangerouslySetInnerHTML={{ __html: templatedValue }}
    />
  }, [templateRefs, templateData, authorization, mfrProfile])

  return (
    <RoleContext.Consumer>
      {roleCtx => (<>
        <Dialog
            open={props.showVariableOptions}
            onClose={props.onCloseVariableOptions}
          >
            <DialogTitle>Template Variable Options</DialogTitle>
            <DialogContent>
              <Grid container>
                {Object.keys(templateVariables).map((v, i) => {
                  return <Grid key={`variable-values-${i}`} item xs={12} md={4} lg={4}>{v}</Grid>
                })}
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={props.onCloseVariableOptions}>Close</Button>
            </DialogActions>
          </Dialog>

        <div ref={letterRef} style={styles.letter}>
          <div style={styles.innerLetter}>
            <EmptyWhiteRow />
            <EmptyWhiteRow />
            <div ref={headerRef}>
              <div style={styles.header}>
                <div style={styles.logo}><img style={styles.logoImg} src={`data:image/png;base64, ${cocaColaNaB64}`} alt='Coca-Cola North America' /></div>
                <div style={styles.title}><TemplatedText id='title' /></div>
              </div>
            </div>

            <div ref={bodyRef}>
              <div style={styles.container}>
                <EmptyRow />

                <div style={styles.infoSection}>
                  <IssuedDate />
                  <ReviewedDate />
                  <RevisionDate />
                  <EmptyRow />
                  <TemplatedText id='to' />
                  <EmptyRow />
                  <TemplatedText id='salutation' />
                  <EmptyRow />
                  <TemplatedText id='authStatement' />

                  {authorization.approvalCondition ? <>
                    <EmptyRow />
                    <span>{authorization.approvalCondition}</span>
                  </> : undefined}

                  <EmptyRow />
                  <TemplatedText id='body' />

                </div>

                <EmptyRow />
                <table style={styles.table}>
                  {approvalsByCategory?.map((uniqueCategory, i) => {
                      const { categoryId, categoryLabel, approvals, cols } = uniqueCategory
                      return <tbody key={`approval-category-${i}`}>
                        <tr style={styles.tableRow}>
                          <th style={{ ...styles.tableHeader, ...styles.alignLeft }} colSpan={6 - cols}>{categoryLabel}</th>
                          {hasMostSensitiveCol(categoryId) && <th style={{ ...styles.tableHeader, ...styles.alignCenter }}>Most Sensitive Product for Line</th>}
                          {hasLineCol(categoryId) && <th style={{ ...styles.tableHeader, ...styles.alignCenter }}>Line</th>}
                          <th style={{ ...styles.tableHeader, ...styles.alignCenter }}>Approval Date</th>
                          <th style={{ ...styles.tableHeader, ...styles.alignCenter }}>Approval Status</th>
                        </tr>
                        {approvals.map((approval, j) => {
                          return <tr key={`approval-${j}`}>
                            <td style={{ ...styles.tableCell, ...styles.alignLeft }} colSpan={6 - cols}>{approval.description}</td>
                            {hasMostSensitiveCol(categoryId) && <td style={{ ...styles.tableCell, ...styles.alignCenter }}>{approval.mostSensitiveProduct}</td>}
                            {hasLineCol(categoryId) && <td style={{ ...styles.tableCell, ...styles.alignCenter }}>{approval.line}</td>}
                            <td style={{ ...styles.tableCell, ...styles.alignCenter }}>{toFormattedDate(approval.date)}</td>
                            <td style={{ ...styles.tableCell, ...styles.alignCenter }}>{approval.approvalStatus === approvalStatuses.PROVISIONAL ? "*" : ""}{toPascalCase(approval.approvalStatus)}</td>
                          </tr>
                        })}
                      </tbody>
                    })
                  }
                </table>
                {hasAtLeastOneProvisionalApproval && <TemplatedText id='provisionalStatement' />}
                <EmptyRow />
                <TemplatedText id='finalApprovalStatus' />
                <EmptyRow />
                <TemplatedText id='complimentaryClose' />
                <div style={styles.signatureBox}></div>
                {signed && <span style={styles.signature[props.signatureStyle]}>{firstName} {lastName}</span>}
                {!signed && <div style={styles.signPrompt}><Button variant="contained" onClick={() => props.onClickSign()} endIcon={<HistoryEdu />}>Sign</Button></div>}
                <TemplatedText id='from' />
              </div>
            </div>

            <div id="footer" ref={footerRef}>
              <div style={styles.footer}>
                <div style={styles.footerVersion}>Issued on: {date}</div>
                <div style={styles.footerPages}>Page: <span className="pageNumber"> of <span className="totalPages"></span></span></div>
              </div>
            </div>
          </div>
        </div>
        </>)}
    </RoleContext.Consumer>
  )
}