import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Card, CardContent, CardHeader, Collapse, Typography } from "@mui/material"

import React, { useState } from "react"

export default function HelpGuidanceCollapsible(props={
  helpTitle: undefined,
  helpMessage: undefined,
  defaultExpanded: true
}) {
  const [expanded, setExpanded] = useState((props.defaultExpanded === undefined ? true : props.defaultExpanded))

  const isOpen = Boolean(expanded)

  return (
    <>
      <Card className='help-guidance'>
        <CardHeader title={props.helpTitle}
          action={
              isOpen ?
              <ExpandLess onClick={() => setExpanded (!expanded)} />
              :
              <ExpandMore onClick={() => setExpanded(!expanded)}
              />
            
          } />
        <Collapse in={isOpen}>
        <CardContent >
          <Typography whiteSpace={'pre-wrap'} variant="inherit">{props.helpMessage}</Typography>
        </CardContent>
        </Collapse>
      </Card>
    </>
  )
}