import { useState, useMemo, useEffect, useCallback } from 'react'
import { checkColumnError, checkEmailError, checkPhoneError } from '../form/Form'
import { Grid, FormControlLabel, Checkbox, Autocomplete, FormHelperText, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Stack, TextField } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';

export default function ContactFormModal({ contactAssociationName, notificationsName, isInternal, contactLookup, mode: propsMode, contactOptions, getCurrentValues, open, columns, onClose, addRow, rowSetter, onSubmit, parentType, facilityOrOwnershipUuid, contactTypeOptions: propsContactTypeOptions }) {
  const [values, setValues] = useState({});

  useEffect(() => {
    if (getCurrentValues) {
      setValues(getCurrentValues())
    }
  }, [getCurrentValues])

  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [existingContact, setExistingContact] = useState(null)
  const [emailSearches, setEmailSearches] = useState([])
  const [dirty, setDirty] = useState(false)
  const [mode, setMode] = useState(propsMode)
  const [contactTypeOptions, setContactTypeOptions] = useState([])

  const filterContactTypeOptions = (cto = [], ct = []) => {
    const otherSelected = ct.some(x => x?.ContactType?.name === 'Other')
    const selectedCount = ct.length
    if(otherSelected) return cto.filter(x => x.name === 'Other')
    if(selectedCount) return cto.filter(x => x.name !== 'Other')
    return cto
  }
  
  useEffect(() => {
    const cto = filterContactTypeOptions(propsContactTypeOptions, values[contactAssociationName])
    setContactTypeOptions(cto)
  }, [values, contactAssociationName, propsContactTypeOptions])

  const contactTypes = values[contactAssociationName]
  useEffect(() => {
  }, [contactTypes])

  useEffect(() => {
    setMode(propsMode)
  }, [propsMode])

  const validate = useCallback(() => {
    const requiredMsg = 'This field is required'
    const _errors = {...errors}
    if(Object.keys(_errors).length > 0) return false

    if(!values.email) _errors.email = requiredMsg
    if(values.email && checkEmailError(values.email)) _errors.email = 'Not a valid Email'
    if(!values.name) _errors.name = requiredMsg
    if(!values[contactAssociationName]?.length) _errors[contactAssociationName] = 'At least one Contact Type is required'
    // if(!values.jobTitle) _errors.jobTitle = requiredMsg
    // if(!values.phone) _errors.phone = requiredMsg
    // if(values.phone && checkPhoneError(values.phone)) _errors.phone = 'Not a valid Phone Number'

    if(Object.keys(_errors).length > 0) {
      setErrors(_errors)
      return false
    }
    return true
  }, [contactAssociationName, values, errors])

  const handleSubmit = useCallback(async () => {
    if (validate()) {
      setSaving(true)
      if (onSubmit) {
        await onSubmit(values)
      } else {
        addRow(rowSetter, values)
      }
      setErrors({});
      setValues({});
      setSaving(false)
      onClose();
    }
  }, [values, validate, addRow, onClose, onSubmit, rowSetter])

  const contactTypeValues = useMemo(() => {
    if (values[contactAssociationName]?.length) {
      return values[contactAssociationName]?.map(oc => {
        return contactTypeOptions.find(cto => cto.id === oc.ContactType?.id)
      })
    } else {
      return []
    }
  }, [contactAssociationName, values, contactTypeOptions])

  const trendsNotificationChecked = useMemo(() => {
    if (!values?.[notificationsName]?.length) return false
    return values?.[notificationsName]?.some(notification => {
      return notification.notificationType === 'trend'
    }) || false
  }, [values, notificationsName])

  return (
    <Dialog open={open} fullWidth maxWidth='md'>
      <DialogTitle textAlign="center">{mode.charAt(0).toUpperCase() + mode.slice(1)} Record</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            margin={1}
            spacing={2}
          >
            <TextField
              key='email'
              label='Email'
              name='email'
              size='small'
              disabled={loading || mode === 'edit' || saving}
              value={values?.email || ''}
              onChange={(e) => {
                const email = e.target.value?.toLowerCase() || ''
                setValues({ ...values, [e.target.name]: email })
              }}
              error={!!errors.email}
              helperText={errors.email}
              onBlur={() => {
                checkColumnError('email', values.email, errors, setErrors, true)
                if(!values.email) return

                const alreadyInList = contactOptions.find(f => f.email.toLowerCase() === values.email.toLowerCase())
                if (alreadyInList) {
                  setValues(alreadyInList)
                  if(mode === 'add') setMode('edit')
                    return
                }

                (async () => {
                  setLoading(true)
                  const existing = await contactLookup(values.email)
                  setEmailSearches([...emailSearches, { existing, email: values.email }])
                  if (existing) {
                    setValues(existing)
                    setExistingContact(existing)
                  } else {
                    setExistingContact(null)
                    setValues({email: values.email})
                  }
                  setLoading(false)
                })()
              }}
            />

            <TextField
              key='name'
              label='Name'
              name='name'
              size='small'
              disabled={loading || existingContact || saving}
              value={values?.name || ''}
              onChange={(e) => {
                setDirty(true)
                setValues({ ...values, [e.target.name]: e.target.value })
              }}
              error={!!errors.name}
              helperText={errors.name}
              onBlur={() => checkColumnError('name', values.name, errors, setErrors, true)}
            />

            <FormControl error={errors[contactAssociationName]}
              size='small'>

              <Autocomplete
                disabled={loading || saving}
                value={contactTypeValues}
                options={contactTypeOptions}
                getOptionLabel={option => option?.name}
                renderInput={(params) => <TextField {...params} label="Contact Types" />}
                multiple
                onChange={(e, newValue) => {
                  setDirty(true)
                  setValues({ ...values, [contactAssociationName]: newValue.map(ContactType => ({ ContactType })) })
                }}
                onBlur={(e) => {
                  if (!values[contactAssociationName]?.length) {
                    setErrors({ ...errors, [contactAssociationName]: `At least one contact type is required` })
                  }
                }}
                size='small'
              />
              <FormHelperText error={!values[contactAssociationName]?.length}>{errors[contactAssociationName]}</FormHelperText>
            </FormControl>

            <Grid container>

              <Grid item xs={6}>
                <Stack
                  margin={1}
                  spacing={2}
                >
                  <TextField
                    key='jobTitle'
                    label='Job Title'
                    name='jobTitle'
                    size='small'
                    disabled={loading || existingContact || saving}
                    value={values?.jobTitle || ''}
                    onChange={(e) => {
                      setDirty(true)
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }}
                    error={!!errors.jobTitle}
                    helperText={errors.jobTitle}
                    // onBlur={() => checkColumnError('jobTitle', values.jobTitle, errors, setErrors, true)}
                  />

                  <MuiTelInput
                    size='small'
                    defaultCountry='US'
                    disableDropdown
                    forceCallingCode
                    value={values?.phone || ''}
                    disabled={loading || existingContact || saving}
                    onChange={(event) => {
                      setDirty(true)
                      setValues({ ...values, phone: event })
                    }}
                    label='Phone'
                    placeholder='Phone'
                    name='phone'
                    key='phone'
                    error={!!errors.phone}
                    helperText={errors.phone}
                    onBlur={() => checkColumnError('phone', values.phone, errors, setErrors, false)}
                  />

                  <TextField
                    key='extension'
                    label='Extension'
                    name='extension'
                    value={values?.extension || ''}
                    disabled={loading || existingContact || saving}
                    size='small'
                    onChange={(e) => {
                      setDirty(true)
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }}
                    error={!!errors.extension}
                    helperText={errors.extension}
                    onBlur={() => checkColumnError('extension', values.extension, errors, setErrors, false)}
                  />
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Stack
                  margin={1}
                  spacing={2}
                >
                  <TextField
                    multiline
                    key='note'
                    label='Note'
                    name='note'
                    value={values?.note || ''}
                    disabled={loading || existingContact || saving}
                    size='small'
                    onChange={(e) => {
                      setDirty(true)
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }}
                    error={!!errors.note}
                    helperText={`${values?.note?.length ?? 0}/255 ${errors.note ?? ''}`}
                    inputProps={{
                      maxLength: '255'
                    }}
                    onBlur={() => checkColumnError('note', values.note, errors, setErrors, false)}
                  />
                </Stack>
              </Grid>
            </Grid>

            <FormControlLabel
              control={<Checkbox
                checked={trendsNotificationChecked}
                disabled={loading || existingContact || saving}
                onChange={(e) => {
                  setDirty(true)
                  const { checked } = e.target
                  values[notificationsName] = values[notificationsName] || []
                  const plantTrendsItem = values?.[notificationsName].find(n => n.notificationType === 'trend')
                  const notifications = [...values?.[notificationsName]]
                  if (plantTrendsItem && checked) return
                  if (plantTrendsItem && !checked) notifications.splice(notifications.indexOf(plantTrendsItem), 1)
                  if (!plantTrendsItem && checked) notifications.push({ notificationType: 'trend' })
                  setValues({ ...values, [notificationsName]: notifications })
                }}
              />}
              label="Recieves Plant Trend Notifications"
            />

          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={() => { setDirty(false); setErrors({}); setValues({}); onClose(); }}>Cancel</Button>
        <LoadingButton
          size="small"
          color="primary"
          onClick={handleSubmit}
          loading={saving}
          disabled={!dirty}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
        >
          <span>{mode === 'add' ? 'Add New' : 'Save'} Contact</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};