import { getRequestConfig } from '../auth/auth';
import { ApiGateway } from '../config/config';
import axios from 'axios'

export const ParentType = {
  ownership: 'ownership',
  manufacturer: 'manufacturer',
}

export const listContacts = async (parentType, facilityOrOwnershipUuid) => {
  const requestConfig = await getRequestConfig()

  const path = `${ApiGateway.facilityManagement}/${ParentType[parentType]}/${facilityOrOwnershipUuid}/contacts`
  const response = await axios.get(path, requestConfig)
  if (response.data) {
    const { results } = response.data
    return results
  }
}

export const getContactByEmail = async (email, isInternal) => {
  const requestConfig = await getRequestConfig()

  const path = `${ApiGateway.facilityManagement}/contact/search`
  const response = await axios.post(path, {
    email,
    isInternal
  }, requestConfig)
  if (response.data) {
    const { results } = response.data
    return results?.length ? results.find(r => r.isInternal === isInternal) : null
  }
}

export const createContact = async (contact) => {
  const requestConfig = await getRequestConfig()
  const path = `${ApiGateway.facilityManagement}/contact`
  const response = await axios.put(path, contact, requestConfig)
  if (response.data) {
    const { instance } = response.data
    return instance
  }
}

export const updateContact = async (contact) => {
  const requestConfig = await getRequestConfig()
  const path = `${ApiGateway.facilityManagement}/contact/${contact?.id}`
  const response = await axios.put(path, contact, requestConfig)
  if (response.data) {
    const { instance } = response.data
    return instance
  }
}

export const createContactAssociation = async (parentType, facilityOrOwnershipUuid, contact) => {
  const requestConfig = await getRequestConfig()

  const path = `${ApiGateway.facilityManagement}/${ParentType[parentType]}/${facilityOrOwnershipUuid}/contacts`
  const response = await axios.put(path, contact, requestConfig)
  if (response.data) {
    const { instance } = response.data
    return instance
  }
}

export const createContactNotification = async ({
  contactId,
  facilityOrOwnershipUuid,
  parentType,
  notificationType
}) => {
  const requestConfig = await getRequestConfig()
  const path = `${ApiGateway.facilityManagement}/${parentType}/${facilityOrOwnershipUuid}/contacts/${contactId}/notifications`
  const response = await axios.put(path, { notificationType }, requestConfig)
  if (response.data) {
    const { instance } = response.data
    return instance
  }
}

export const deleteContactNotification = async ({
  contactNotificationId,
  parentType,
}) => {
  const requestConfig = await getRequestConfig()

  const path = `${ApiGateway.facilityManagement}/${parentType}/contact-notifications/${contactNotificationId}`
  const response = await axios.delete(path, requestConfig)
}

export const updateOwnershipContact = async (parentType, facilityOrOwnershipUuid, ownershipContact) => {
  const promises = [
    deleteContactAssociation(parentType, facilityOrOwnershipUuid, ownershipContact.id),
    createContactAssociation(parentType, facilityOrOwnershipUuid, ownershipContact.Contact)
  ]
  const [deleteResponse, createResponse] = await Promise.all(promises)
  return createResponse
}

export const deleteContact = async (contactId) => {
  const requestConfig = await getRequestConfig()

  const path = `${ApiGateway.facilityManagement}/contact/${contactId}`
  const response = await axios.delete(path, requestConfig)
}

export const deleteContactAssociation = async (parentType, facilityOrOwnershipUuid, ownershipContactId) => {
  const requestConfig = await getRequestConfig()

  const path = `${ApiGateway.facilityManagement}/${ParentType[parentType]}/${facilityOrOwnershipUuid}/contacts/${ownershipContactId}`
  const response = await axios.delete(path, requestConfig)
}

export const getContactTypes = async (category, isInternal) => {
  const requestConfig = await getRequestConfig()
  const queryParams = []
  if (isInternal !== undefined) queryParams.push(`isInternal=${isInternal}`)
  if (category !== undefined) queryParams.push(`category=${category}`)

  const query = queryParams.length > 1
    ? queryParams.join('&') :
    queryParams.length === 0 ? '' :
      queryParams.join('&')

  const path = `${ApiGateway.facilityManagement}/contact-types?${query}`
  const response = await axios.get(path, requestConfig)
  if (response.data) {
    const { results } = response.data
    return results
  }
}

export const getAssociatedContactByEmail = async (facilityOrOwnershipUuid, parentType, email) => {
  const requestConfig = await getRequestConfig()

  const search = {
    [`${parentType}Uuid`]: facilityOrOwnershipUuid,
    Contact: { email }
  }
  const path = `${ApiGateway.facilityManagement}/${ParentType[parentType]}/${facilityOrOwnershipUuid}/contacts/search`
  const response = await axios.post(path, search, requestConfig)
  if (response.data) {
    const { results } = response.data
    return results?.length ? results[0] : null
  }
  return []
} 

export const getVisibleContactByEmail = async ({manufacturerUuid, ownershipUuid, email}) => {
  const requestConfig = await getRequestConfig()
  if(!email && !ownershipUuid) throw new Error('ownershipUuid and email are required')

  const search = {
    // manufacturerUuid,
    ownershipUuid,
    email,
  }

  const path = `${ApiGateway.facilityManagement}/contact/search`
  const response = await axios.post(path, search, requestConfig)
  if (response.data) {
    const { results } = response.data
    return results?.length ? results[0] : null
  }
  return []
}

export const getEntityContacts = async ({
    facilityOrOwnershipUuid,
    parentType,
    isInternal,
    email,
    withIds,
    notId,
    category
  }) => {
  
  if(!facilityOrOwnershipUuid || !parentType) {
    throw new Error('Must supply both facilityOrOwnershipUuid & parentType')
  }
  const requestConfig = await getRequestConfig()

  const search = {
    [`${parentType}Uuid`]: facilityOrOwnershipUuid
  }

  search.ContactType = {}
  if(email !== undefined) search.Contact = { email }
  if(isInternal !== undefined) search.ContactType = { isInternal }
  if(withIds !== undefined) search.ContactType = { ...search.ContactType, id: withIds }
  if(notId !== undefined) search.ContactType = { ...search.ContactType, notId }
  if(category !== undefined) search.ContactType = { ...search.ContactType, category }

  const path = `${ApiGateway.facilityManagement}/${ParentType[parentType]}/${facilityOrOwnershipUuid}/contacts/search`
  const response = await axios.post(path, search, requestConfig)
  if (response.data) {
    const { results } = response.data
    return results
  }
  return []
}

export const getOrCreateAssociatedContact = async ({ownerhsipUuid, contact}) => {
  const existingContact = await getVisibleContactByEmail({
    ownerhsipUuid,
    email: contact.email,
  })

  if(existingContact?.id) return existingContact

  const newContact = await createContact(contact)
  return newContact
}