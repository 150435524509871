import { default as React, useContext, useEffect, useState } from 'react'
import { Paper } from '@mui/material'
import { Factory } from '@mui/icons-material'
import ConfirmDialog from '../form/ConfirmDialog'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import Page from '../layout/Page'
import { useParams, useLocation, useNavigate, useBlocker } from 'react-router-dom'
import ProfileSectionNavigator from '../form/ProfileSectionNavigator';
import { createManufacturerProfile, getOpenAssessment, loadManufacturer, loadManufacturerProfile, loadOpenAssessment } from './data';
import ManufacturerDetailsSection from './sections/ManufacturerDetailsSection'
import ManufacturerProfileSection from './sections/ManufacturerProfileSection'
import ManufacturerContactsSection from './sections/ManufacturerContactsSection'
import ManufacturerAssmtContactsSection from './sections/ManufacturerAssmtContactsSection'
import EventBus from '../utils/EventBus';
import EventNames from '../utils/EventNames'
import ManufacturerProfileChildItemSection from './sections/ManufacturerProfileChildItemSection'
import { MANUFACTURING_FACILITY_TYPES } from '../utils/constants'

export default function EditManufacturerPage(props = {}) {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [tryAgainDialog, setTryAgainDialog] = useState(false)
  const [manufacturer, setManufacturer] = useState({})
  const [manufacturerProfile, setManufacturerProfile] = useState(null)
  const [openAssessment, setOpenAssessment] = useState(null)
  const [CurrentSection, setCurrentSection] = useState()
  const [isEditAllowed, setIsEditAllowed] = useState(false)
  const [isValidForSave, setIsValidForSave] = useState(false)
  const [profileSections, setProfileSections] = useState([])
  const [profileEligible, setProfileEligible] = useState(false)

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isValidForSave &&
      currentLocation.pathname !== nextLocation.pathname
  )

  const breadcrumb = <div><span><Factory />&nbsp;{manufacturer?.manufacturerName || 'Loading Facility...'}</span></div>

  const onSave = async () => {
    EventBus.emit('saveRequested')
  }

  const onStateValidation = (inst, valid) => {
    setIsValidForSave(valid)
  }

  const tryAgainSave = async () => {
    // hideTryAgainDialog()
    await onSave()
  }

  const confirmCancel = () => {
    if (isValidForSave) {
      setConfirmDialog(true)
    } else handleDiscard()
  }

  const handleRemain = () => {
    setConfirmDialog(false)
    if (blocker.state === 'blocked') {
      blocker.reset()
    }
  }

  const handleDiscard = () => {
    setConfirmDialog(false)
    setIsValidForSave(false)
    setEditMode(false)
    if (blocker.state === 'blocked') {
      blocker.proceed()
    }
  }

  const handleClose = () => {
    // if (location.key === 'default') {
    navigate(`/manufacturer/list`)
    // } else {
    //   navigate(-1)
    // }
  }

  let saveUnsuccessfulError
  const getSaveUnsuccessfulText = () => {
    return `Could not save due to ${saveUnsuccessfulError}, do you want to try again?`
  }
  const hideTryAgainDialog = () => {
    setTryAgainDialog(false)
  }

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const [confirmDialog, setConfirmDialog] = useState(false)

  const hideDialog = () => {
    setConfirmDialog(false)
  }

  const [editMode, setEditMode] = useState(false)

  const onEdit = () => {
    setEditMode(true)
  }

  // const loadOpenAssessment = async ( _mfrProfile ) => {
  //   if (_mfrProfile) {
  //     const searchResult = await getOpenAssessment( _mfrProfile.id )
  //     if (searchResult) {
  //       setOpenAssessment(searchResult)
  //       return
  //     }
  //   }
  //   setOpenAssessment(null)
  // }

  const initializePage = async () => {
    startLoading()
    const _man = await loadManufacturer(params.manufacturerUuid)
    if (_man) {
      EventBus.emit(EventNames.ON_MENU_CONTEXT_CHANGE, {manufacturer: _man, manufacturerUuid: _man.uuid})
      const adds = await Promise.all([
        loadManufacturerProfile({ manufacturerId: _man.manufacturerId }),
        loadOpenAssessment({ manufacturerId: _man.manufacturerId })
      ])
      const profile = adds[0]
      if (profile) {
        setManufacturerProfile(profile)
        EventBus.emit(EventNames.ON_MENU_CONTEXT_CHANGE, {manufacturer: _man, manufacturerProfile: profile, manufacturerUuid: _man.uuid})
        // await loadOpenAssessment( profile.id )
      }
      const assessment = adds[1]
      if (assessment) {
        setOpenAssessment(adds[1])
      } else setOpenAssessment(null)
    }
    setManufacturer(_man)
    stopLoading()
  }

  useEffect(() => {
    initializePage()
  }, [])

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirmCancel()
    }
  }, [blocker.state])

  const createProfile = async () => {
    startLoading()
    const profile = await createManufacturerProfile(manufacturer)
    stopLoading()
    if (profile && profile.status !== 'error') {
      navigate(`/manufacturer/edit/${params.manufacturerUuid}/profile`, { replace: true })
    }
  }

  useEffect(() => {
    if (manufacturer && isEditAllowed && !manufacturerProfile) {
      if (MANUFACTURING_FACILITY_TYPES.includes(manufacturer.type) 
        && ![undefined, null].includes(manufacturer.manufacturerId)
      ) {
        return setProfileEligible(true)
      }
    }
    setProfileEligible(false)
  }, [manufacturer, manufacturerProfile, isEditAllowed])


  useEffect(() => {
    const sectionProps = {
      manufacturer,
      editMode,
      setSectionIsEditable: setIsEditAllowed,
      onStateValidation,
      startLoading,
      stopLoading
    }

    const sections = [{
      name: 'Facility Details',
      href: `/manufacturer/edit/${params.manufacturerUuid}/details`,
      el: <ManufacturerDetailsSection {...sectionProps} setManufacturer={setManufacturer} />
    },
    {
      name: 'Contacts',
      href: `/manufacturer/edit/${params.manufacturerUuid}/contacts`,
      el: <ManufacturerContactsSection {...sectionProps} />
    }]

    if (manufacturerProfile) {
      sectionProps.manufacturerProfile = manufacturerProfile
      sectionProps.setManufacturerProfile = setManufacturerProfile
      sectionProps.openAssessment = openAssessment

      const productItemTypes = {
        PROCESS_TYPE_LINE: 'process-type-line',
        RAW_MATERIAL: 'raw-material',
        COMAN_PRODUCT: 'comanufactured-product',
        BACKUP_LOCATION: 'backup-location'
      }

       sections.push(...[
        {
          name: 'Assessment Contacts',
          href: `/manufacturer/edit/${params.manufacturerUuid}/assessment-contacts`,
          el: <ManufacturerAssmtContactsSection {...sectionProps} />
        },
        {
          name: 'Manufacturer Profile',
          href: `/manufacturer/edit/${params.manufacturerUuid}/profile`,
          el: <ManufacturerProfileSection {...sectionProps} />
        },
        {
          name: 'Products & Process Types',
          href: `/manufacturer/edit/${params.manufacturerUuid}/products-processes`,
          el: <ManufacturerProfileChildItemSection {...sectionProps} childItemType={productItemTypes.PROCESS_TYPE_LINE} />
        },
        {
          name: 'Raw Materials',
          href: `/manufacturer/edit/${params.manufacturerUuid}/raw-materials`,
          el: <ManufacturerProfileChildItemSection {...sectionProps} childItemType={productItemTypes.RAW_MATERIAL} />
        },
        {
          name: 'Co-Manufactured Products',
          href: `/manufacturer/edit/${params.manufacturerUuid}/co-manufactured`,
          el: <ManufacturerProfileChildItemSection {...sectionProps} childItemType={productItemTypes.COMAN_PRODUCT} />
        },
        {
          name: 'Business Continuity',
          href: `/manufacturer/edit/${params.manufacturerUuid}/business-continuity`,
          el: <ManufacturerProfileChildItemSection {...sectionProps} childItemType={productItemTypes.BACKUP_LOCATION} />
        }
      ])
    }

    setProfileSections(sections)
  }, [
    params.manufacturerUuid,
    manufacturer,
    manufacturerProfile,
    editMode
  ])

  return (
    <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={
      <FormViewCommandBar
        onSave={onSave}
        onEdit={onEdit}
        onCancel={confirmCancel}
        onClose={handleClose}
        enableSave={isValidForSave}
        showClose={!editMode}
        showCancel={editMode}
        showEdit={!editMode && isEditAllowed}
        showSave={editMode && isEditAllowed}
        actionButtons={[
          {
            label: 'Create Profile',
            onClick: createProfile,
            endIcon: undefined,
            enabled: profileEligible && !editMode,
            show: profileEligible
        }]}
      />
    }>
      <ConfirmDialog title="Discard all changes?" text="You will not be able to recover your work." open={confirmDialog} hideDialog={handleRemain} handleConfirm={handleDiscard} yesLabel="Discard" noLabel="Stay on Page" />
      <ConfirmDialog title="Save was not successful" text={getSaveUnsuccessfulText()} open={tryAgainDialog} hideDialog={hideTryAgainDialog} handleConfirm={tryAgainSave} yesLabel="Try Again" noLabel="Cancel" />
      <ProfileSectionNavigator
        setCurrentSection={setCurrentSection}
        profileSections={profileSections}
      />
      <Paper
        component="form"
        sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', margin: '1em auto' }}
      >
        {manufacturer && CurrentSection}
      </Paper>
    </Page>
  )
}